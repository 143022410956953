import React, { FC, useState, useRef, useContext } from 'react'
import { Grid, Button, TextField, Typography } from '@mui/material'
import TextFieldModal from '../../components/modal/textFieldModal'
import { styles } from './login-style'
import { restore } from '../../redux/reducers/auth_reducer'
import { useDispatch } from 'react-redux'
import { SubmitHandler, useForm } from 'react-hook-form'
import { LanguageContext, translate } from '../../constants/lang'

export const Restore: FC<any> = (props) => {
  let dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [restoreEmail, setRestoreEmail] = useState('')
  const [restoreState, setRestoreState] = useState('restore')
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRestoreEmail(event.target.value)
  }
  const sendRestore = (): void => {
    dispatch(restore(restoreEmail))
    setRestoreEmail('')
    setRestoreState('restoreDone')
  }
  const { language } = useContext(LanguageContext)
  return (
    <>
      {restoreState === 'restore' ? (
        <Grid item xs={6} sx={styles.column}>
          <Typography sx={styles.restore}>{translate('restoreAccess', language)}</Typography>
          <Typography sx={{ ...styles.restoreDesc, ...styles.mb32 }}>
            {translate('restoreAccessInstructionSent', language)}
          </Typography>
          <TextFieldModal
            label="e-mail"
            sx={styles.mb48}
            type="text"
            {...register('mail', {
              onChange: handleEmailChange,
              required: 'Требуется ввести e-mail пользователя',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Введенный e-mail не соответствует формату',
              },
            })}
            error={!!errors.mail}
            helperText={errors.mail?.message}
          />
          <Grid container>
            <Grid item xs={5}>
              <Button variant="contained" size="large" onClick={handleSubmit(sendRestore)} sx={styles.button}>
                {translate('send', language)}
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button size="large" onClick={() => props.setLoginState('auth')} sx={styles.button}>
                {translate('labelCancel', language)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={6} sx={styles.column}>
          <Typography sx={styles.restore}>{translate('labelDone', language) + '!'}</Typography>
          <Typography sx={{ ...styles.restoreDesc, ...styles.mb48 }}>
            {translate('restoreAccessInstructionSent', language)}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setRestoreState('restore')
                  props.setLoginState('auth')
                }}
                sx={styles.button}
              >
                {translate('labelContinue', language)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
