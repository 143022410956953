import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { accomulatePermissionsType, PermissionType, RightsType } from '../../types/types'
import { RightsAPI } from '../../api/rights'
const SAVE_PHOTO_SUCCES = 'SAVE_PHOTO_SUCCES'
const SET_RIGHTS = 'SET_RIGHTS'
const SET_RIGHTS_ERROR = 'SET_RIGHTS_ERROR'
const SET_ACCOMULATE_PERMISSIONS = 'SET_ACCOMULATE_PERMISSIONS'

let initialstate = {
  rights: [] as Array<RightsType>,
  error: false as boolean,
}

export type InitialstateType = typeof initialstate

const rights_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_RIGHTS: {
      return { ...state, rights: action.rights }
    }
    case SET_RIGHTS_ERROR: {
      return { ...state, error: true }
    }
    case SET_ACCOMULATE_PERMISSIONS: {
      return { ...state, ...(state.rights[action.i].accomulatePermissions = action.permissions) }
    }
    default:
      return state
  }
}

export default rights_reducer

type ActionsType = SetRightsType | setAccomulatePermissionsType | setRightsTypeError

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetRightsType = {
  type: typeof SET_RIGHTS
  rights: Array<RightsType>
}

type setRightsTypeError = {
  type: typeof SET_RIGHTS_ERROR
  error: boolean
}

export let setRights = (rights: Array<RightsType>): SetRightsType => {
  return {
    type: SET_RIGHTS,
    rights,
  }
}

export let setRightsError = (error: boolean): setRightsTypeError => {
  return {
    type: SET_RIGHTS_ERROR,
    error,
  }
}

export let getRights = (): ThunkType => async (dispatch: DispatchType) => {
  try {
    let rightsData = await RightsAPI.getRights()
    dispatch(setRights(rightsData))
  } catch (e: any) {
    console.log('Error:', JSON.stringify(e.response.data, null, 4))
    dispatch(setRightsError(true))
  }
}

type setAccomulatePermissionsType = {
  type: typeof SET_ACCOMULATE_PERMISSIONS
  permissions: accomulatePermissionsType
  i: number
}

export let setAccomulatePermission = (
  permissions: accomulatePermissionsType,
  i: number,
): setAccomulatePermissionsType => {
  return {
    type: SET_ACCOMULATE_PERMISSIONS,
    permissions,
    i,
  }
}

export let accomulatePermissions =
  (permissions: accomulatePermissionsType, i: number): ThunkType =>
  async (dispatch: DispatchType) => {
    dispatch(setAccomulatePermission(permissions, i))
  }

export let addRight =
  (id: string, name: string, description: string[], permissions: PermissionType[]): ThunkType =>
  async (dispatch: DispatchType) => {
    let response = await RightsAPI.addRights(id, name, description, permissions)
    if (response == 'ok') {
      let rightsData = await RightsAPI.getRights()
      dispatch(setRights(rightsData))
    }
    // dispatch(setuserprofile({ fio, alias, mail, organizationOpt, positionOpt }))
  }

export let saveEditRight =
  (id: string, name: string, description: string[], permissions: PermissionType[]): ThunkType =>
  async (dispatch: DispatchType) => {
    let response = await RightsAPI.editRights(id, name, description, permissions)
    if (response == 'ok') {
      let rightsData = await RightsAPI.getRights()
      dispatch(setRights(rightsData))
    }
    // dispatch(setuserprofile({ fio, alias, mail, organizationOpt, positionOpt }))
  }

export let removeRight =
  (right: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    let response = await RightsAPI.deleteRights(right)
    if (response == 'ok') {
      let rightsData = await RightsAPI.getRights()
      dispatch(setRights(rightsData))
    }
  }
