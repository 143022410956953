import React, { FC, useContext, useState } from 'react'
import { Box, Button, Chip, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import ListUser from './ListUser'
import { useDispatch, useSelector } from 'react-redux'
import { getParticipants, setParticipants, nullParticipants } from '../../../../redux/reducers/drills_reducer'
import { AppStateType } from '../../../../redux/store'
import { LanguageContext, translate } from '../../../../constants/lang'
import { Done } from '@mui/icons-material'
import ListParticipants from './ListParticipants'
import QuestionMark from '../../../../assets/icon/drill/questionMark'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  box: {
    width: 792,
    maxHeight: 751,
    bgcolor: 'white',
    border: '0px solid #000',
    borderRadius: 6,
    overflow: 'auto',
  },
  info: {
    padding: '24px 44px 12px 44px',
    overflow: 'auto',
    maxHeight: 717,
  },
  title: {
    fontWeight: 800,
    fontSize: 49,
    mb: 2,
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    padding: '5px 3px 5px 7px',
    overflow: 'auto',
  },
  titleChild: {
    fontWeight: 800,
    fontSize: 20,
    mb: 2,
  },
  chipActive: {
    backgroundColor: '#E0F2FF',
    ml: 2,
    borderRadius: 2,
    color: '#4E4B66',
  },
  chipPassive: { ml: 2, borderRadius: 2, color: '#4E4B66' },
  button: {
    m: 4,
  },
  drill: {
    fontWeight: 800,
    fontSize: 20,
  },
}

export const EditParticipants: FC<any> = (props) => {
  const dispatch = useDispatch()
  const [switchUsers, setSwitchUsers] = React.useState<boolean>(false)
  const [checkedUsers, setCheckedUsers] = useState<string[]>([])
  const [checkedParticipants, setCheckedParticipants] = useState<string[]>([])
  const [checkedUsersRole, setCheckedUsersRole] = useState<any>([])
  const { language } = useContext(LanguageContext)

  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    dispatch(getParticipants(props.drillId))
  }, [props.isOpenEditParticipants])
  let selectParticipants = useSelector((store: AppStateType) => store.Drills.participantsSelectTeam)
  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    let arrParticipants = selectParticipants.map((item: any) => {
      item[1].roleId = item[2].id
      item[1].isChecked = true
      item[3].length > 0 ? (item[1].isLeader = true) : (item[1].isLeader = false)
      return item[1]
    })
    let arrParticipantsRole = selectParticipants.map((item: any) => {
      return {
        drillId: props.drillId,
        login: item[1].login,
        roleId: item[1].roleId,
        otherRole: item[1].isLeader,
      }
    })
    let arrUsers = selectParticipants.map((item: any) => item[1].login)
    setCheckedParticipants(arrParticipants)
    setCheckedUsers(arrUsers)
    setCheckedUsersRole(arrParticipantsRole)
  }, [selectParticipants, props.isOpenEditParticipants])

  const setNewParticipants = () => {
    dispatch(setParticipants(checkedUsersRole))
    dispatch(nullParticipants(props.drillId))
    props.closeEditParticipants()
  }

  const tooltipText = (
    <>
      <Box sx={{ mb: 1 }}>
        <b>Все</b> - пользователи добавленные в тренировку
      </Box>
      <Box>
        <b>Участники</b> - список пользователей, добавленных в тренировку с их ролями
      </Box>
    </>
  )

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenEditParticipants}
        onClose={props.closeEditParticipants}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <Box sx={style.info}>
            <Box sx={style.title}>{translate('editParticipants', language)}</Box>
            <Box sx={style.titleChild}>
              {translate('searchAmong', language)}
              <Tooltip title={tooltipText}>
                <Box sx={style.icon}>
                  <QuestionMark />
                </Box>
              </Tooltip>
              <Chip
                label={translate('amongAll', language)}
                onClick={() => {
                  if (switchUsers) {
                    // setFilterRoles([])
                    setSwitchUsers((prev) => !prev)
                  }
                }}
                variant={!switchUsers ? 'filled' : 'outlined'}
                icon={!switchUsers ? <Done /> : <></>}
                sx={!switchUsers ? style.chipActive : style.chipPassive}
              />
              <Chip
                label={translate('participants', language)}
                onClick={() => {
                  if (!switchUsers) {
                    // setFilterUsers([])
                    setSwitchUsers((prev) => !prev)
                  }
                }}
                variant={switchUsers ? 'filled' : 'outlined'}
                icon={switchUsers ? <Done /> : <></>}
                sx={switchUsers ? style.chipActive : style.chipPassive}
              />
            </Box>
            {switchUsers ? (
              <ListParticipants
                checkedUsers={checkedUsers}
                setCheckedUsers={setCheckedUsers}
                checkedParticipants={checkedParticipants}
                checkedUsersRole={checkedUsersRole}
                setCheckedUsersRole={setCheckedUsersRole}
                drillId={props.drillId}
              />
            ) : (
              <ListUser
                checkedUsers={checkedUsers}
                setCheckedUsers={setCheckedUsers}
                checkedParticipants={checkedParticipants}
                checkedUsersRole={checkedUsersRole}
                setCheckedUsersRole={setCheckedUsersRole}
                drillId={props.drillId}
              />
            )}
          </Box>
          <Box sx={style.button}>
            <Button onClick={setNewParticipants} variant="contained" size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelSave', language)}
            </Button>
            <Button onClick={props.closeEditParticipants} size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelCancel', language)}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </>
  )
}
