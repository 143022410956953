import React, { FC } from 'react'
import { Box, Paper } from '@mui/material'

const PaperMain: FC = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '25px 64px',
          zIndex: '-1',
        }}
      >
        <Paper sx={{ width: '100%', height: '85vh', borderRadius: '24px', overflow: 'hidden', padding: '26px' }}>
          {children}
        </Paper>
      </Box>
      <Box height={'1px'}></Box>
    </>
  )
}

export default PaperMain
