import { green } from '@mui/material/colors'
//
export const MainComponentStyles = {
  //Стиль модального окна
  StyleBox: {
    width: '768px',
    minHeight: '750px',
    maxHeight: '900px',
    fontFamily: 'Inter',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '24px',
    padding: '3%',
    overflow: 'overlay',
  },
  StyleBoxFAQ: {
    padding: '17px 32px',
    margin: '15px 0px',
    height: '100%',
    overflow: 'auto',
  },
  StyleDelete: {
    width: '500px',
    maxHeight: '900px',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '24px',
    padding: '3%',
  },
  //Стиль заголовка страницы
  StyleMainPageHeader: {
    height: '35px',
    fontFamily: 'Inter',
    fontWeight: '800',
    fontSize: '40px',
    lineHeight: '48px',
    marginBottom: '2%',
  },
  //Обводка радиокнопок/списка
  StyleBoxBorder: {
    border: '1px solid',
    borderColor: '#E5E5EB',
    borderRadius: '24px',
    padding: '5%',
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '17px',
  },
  //Стиль кнопок сохранить/отмена
  StyleMainButton: {
    width: '145px',
    height: '64px',
    borderRadius: '16px',
    marginRight: '1%',
  },
  //Стиль заголовка модального окна
  StyleMainModalHeader: {
    fontWeight: '800',
    fontSize: '50px',
    color: 'black',
  },
  //Стиль заголовка раздела модального окна
  StyleSecondaryHeader: {
    fontWeight: '800',
    fontSize: '20px',
    color: 'black',
  },
  //Стиль Icon Button
  StyleIconButton: {
    minWidth: '56px',
    minHeight: '56px',
    borderRadius: '16px',
  },
  //Стиль аватарки в модальном окне
  StyleAvatar: {
    bgcolor: green[500],
    borderRadius: '16px',
    width: 175,
    height: 175,
    marginBottom: '2%',
    my: 4,
  },
}
