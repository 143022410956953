import React, { FC, useContext, useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import PaperMain from '../components/common/paper/paper'
import { MainComponentStyles } from '../components/modal/modal-style'
import { LanguageContext, translate } from '../constants/lang'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material'
import Button from '@mui/material/Button'
import axios from 'axios'
import { faqAPI } from '../api/faq'
import { settingsAPI } from '../api/settings'

const SettingsPage: FC = () => {
  const [logs, setLogs] = useState('')
  const { language } = useContext(LanguageContext)
  const data = [
    { role: 'Admin', passwordExpiry: true, twoFactorAuth: true },
    { role: 'Role1', passwordExpiry: false, twoFactorAuth: true },
    { role: 'Role2', passwordExpiry: false, twoFactorAuth: false },
    { role: 'Role3', passwordExpiry: true, twoFactorAuth: false },
  ]

  const saveLogs = async () => {
    try {
      let response = await settingsAPI.getLogs()
      setLogs(response)
      await handleSaveText(response)
    } catch (error) {
      console.error('Error fetching logs:', error)
    }
  }

  const handleSaveText = (logs: string) => {
    const element = document.createElement('a')
    const file = new Blob([logs], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'logs.txt'
    document.body.appendChild(element) // Required for this to work in Firefox
    element.click()
  }

  return (
    <>
      <PaperMain>
        <Box sx={MainComponentStyles.StyleBoxFAQ}>
          <Box style={{ display: 'flex' }}>
            <Typography sx={MainComponentStyles.StyleMainPageHeader}>
              {translate('securitySettings', language)}
            </Typography>
            <Box sx={{ marginLeft: 'auto', marginRight: '0', fontWeight: 'bold' }}></Box>
          </Box>
          <TableContainer style={{ borderRadius: '10px', overflow: 'hidden', fontWeight: 'bold' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#F7F7FC', fontWeight: 'bold' }}>
                  <TableCell>Role</TableCell>
                  <TableCell>Password Expiry</TableCell>
                  <TableCell>Two-factor Authentication</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#F7F7FC' }}>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>
                      <Switch checked={row.passwordExpiry} />
                    </TableCell>
                    <TableCell>
                      <Switch checked={row.twoFactorAuth} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid sx={{ mt: 1 }} container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Button onClick={saveLogs}>{translate('securityEvents', language)}</Button>
            <Button variant="contained">{translate('labelSave', language)}</Button>
          </Grid>
        </Box>
      </PaperMain>
    </>
  )
}

export default SettingsPage
