import { instance } from './instance'
import { RightsType } from '../types/types'
import { PermissionType } from '../pages/rights-page/TableRights'

export const RightsAPI = {
  getRights() {
    return instance.get<Array<RightsType>>(`api/user-role-api`).then((res) => res.data)
  },
  addRights(id: string, name: string, description: string[], permissions: PermissionType[]) {
    return instance.post<string>(`/api/user-role-api`, { id, name, description, permissions }).then((res) => res.data)
  },
  editRights(id: string, name: string, description: string[], permissions: PermissionType[]) {
    return instance.put<string>(`/api/user-role-api`, { id, name, description, permissions }).then((res) => res.data)
  },
  deleteRights(right: string[]) {
    return instance.delete<string>('/api/user-roles-api', { data: right }).then((res) => res.data)
  },
}
