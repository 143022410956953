import * as React from 'react'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { createTheme, Grid } from '@mui/material'
import { ModalUnstyled } from '@mui/base'
import { styled } from '@mui/system'
import { useDispatch } from 'react-redux'
import { removeRight } from '../../../redux/reducers/rights_reducer'
import { ReactComponent as LogoutIcon } from '../../../assets/icon/menu/exit.svg'
import { logout } from '../../../redux/reducers/auth_reducer'
import { useContext } from 'react'
import { LanguageContext, translate } from '../../../constants/lang'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  box: {
    width: 500,
    bgcolor: 'background.paper',
    pr: 8,
    pt: 8,
    pl: 8,
    pb: 4,
    maxHeight: 717,
    border: '0px solid #000',
    borderRadius: 6,
    overflow: 'auto',
    textAlign: 'center',
  },
  head: {
    fontWeight: 700,
    fontSize: 24,
    marginBottom: '8px',
  },
  button: {
    mt: 4,
  },
}

type LogoutModalTypes = {
  closeLogout: () => void
  isOpenLogout: boolean
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#E01010'),
  backgroundColor: '#E01010',
  '&:hover': {
    backgroundColor: '#E01010',
  },
}))

export const LogoutModal: React.FC<LogoutModalTypes> = (props) => {
  let dispatch = useDispatch()
  const { language } = useContext(LanguageContext)
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenLogout}
        onClose={props.closeLogout}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <LogoutIcon />
          <Typography id="modal-modal-title" style={style.head}>
            {translate('exitMessage', language)}
          </Typography>
          <Typography id="modal-modal-description">{translate('exitMessage', language)}</Typography>
          <Grid sx={style.button} container direction="row" justifyContent="flex-end" alignItems="center">
            <Button onClick={props.closeLogout} size="large">
              {translate('labelCancel', language)}
            </Button>
            <Button onClick={() => dispatch(logout())} size="large" variant="contained">
              {translate('exit', language)}
            </Button>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  )
}
