import { UsersAPI } from '../../api/users'
import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { ProfileType, UsersType } from '../../types/types'
import { setRoleError } from './roles_reducer'
const SAVE_PHOTO_SUCCESS = 'SAVE_PHOTO_SUCCESS'
const SET_USERS_PROFILE = 'SET_USERS_PROFILE'
const SET_USERS_PROFILE_ERROR = 'SET_USERS_PROFILE_ERROR'
const SET_ROLE_MAP = 'SET_ROLE_MAP'
const SET_DEFAULT_ROLE = 'SET_DEFAULT_ROLE'
const SET_LOAD = 'SET_LOAD'
const SET_USER_ERROR = 'SET_USER_ERROR'

let initialState = {
  users: [] as Array<ProfileType>,
  roleMap: [] as Array<any>,
  defaultRole: [] as Array<any>,
  isLoad: null as null | boolean,
  error: false as boolean,
  errorUser: '' as string,
}

export type InitialStateType = typeof initialState

const users_reducer = (state = initialState, action: ActionsType): InitialStateType => {
  switch (action.type) {
    case SET_USERS_PROFILE: {
      return { ...state, users: action.users }
    }
    case SET_USERS_PROFILE_ERROR: {
      return { ...state, error: true }
    }
    case SET_ROLE_MAP: {
      return { ...state, roleMap: action.roleMap }
    }
    case SET_DEFAULT_ROLE: {
      return { ...state, defaultRole: action.defaultRole }
    }
    case SET_LOAD: {
      return { ...state, isLoad: action.status }
    }
    case SET_USER_ERROR: {
      return { ...state, errorUser: action.errorUser }
    }
    default:
      return state
  }
}

export default users_reducer

type ActionsType =
  | setUserType
  | setUserTypeError
  | SavePhotoSuccessType
  | userRoleMapType
  | defaultRoleType
  | setLoad
  | setUserErrorType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type setUserType = {
  type: typeof SET_USERS_PROFILE
  users: Array<ProfileType>
}

type setUserTypeError = {
  type: typeof SET_USERS_PROFILE_ERROR
  error: boolean
}

type setUserErrorType = {
  type: typeof SET_USER_ERROR
  errorUser: string
}

export let setUsers = (users: Array<any>): setUserType => {
  return {
    type: SET_USERS_PROFILE,
    users,
  }
}

export let setError = (error: boolean): setUserTypeError => {
  return {
    type: SET_USERS_PROFILE_ERROR,
    error,
  }
}

export let setUserError = (errorUser: string): setUserErrorType => {
  return {
    type: SET_USER_ERROR,
    errorUser,
  }
}

type SavePhotoSuccessType = {
  type: typeof SAVE_PHOTO_SUCCESS
  photos: string
}

export let getUser = (): ThunkType => async (dispatch: DispatchType) => {
  try {
    const usersData = await UsersAPI.getUsers()
    dispatch(setUsers(usersData))
  } catch (e: any) {
    console.log('Error:', JSON.stringify(e.response.data, null, 4))
    dispatch(setError(true))
  }
}

export const addUser =
  (userData: UsersType[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await UsersAPI.addUser(userData)
      if (response === 'ok') {
        const usersData = await UsersAPI.getUsers()
        dispatch(setUsers(usersData))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${response}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка добавления пользователя'))
    }
  }

export let editUser =
  (userData: UsersType[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await UsersAPI.editUser(userData)
      if (response === 'ok') {
        const usersData = await UsersAPI.getUsers()
        dispatch(setUsers(usersData))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${response}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка редактирования пользователя'))
    }
  }

export const removeUser =
  (users: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await UsersAPI.deleteUser(users)
    if (response === 'ok') {
      const usersData = await UsersAPI.getUsers()
      dispatch(setUsers(usersData))
    }
  }

type userRoleMapType = {
  type: typeof SET_ROLE_MAP
  roleMap: Array<any>
}

export let setUserRoleMap = (roleMap: Array<any>): userRoleMapType => {
  return {
    type: SET_ROLE_MAP,
    roleMap,
  }
}

export let getUserRoleMap =
  (login: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const rolesData = await UsersAPI.getUserRoleMap(login)
    dispatch(setUserRoleMap(rolesData))
  }

export const saveUserRoleMap =
  (login: string, roleUserArray: Array<any>): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await UsersAPI.saveUserRoleMap(login, roleUserArray)
      if (response === 'ok') {
        const rolesData = await UsersAPI.getUserRoleMap(login)
        dispatch(setUserRoleMap(rolesData))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${response}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка сохранения ролей'))
    }
  }

type defaultRoleType = {
  type: typeof SET_DEFAULT_ROLE
  defaultRole: Array<any>
}

export let setDefaultRole = (defaultRole: string[]): defaultRoleType => {
  return {
    type: SET_DEFAULT_ROLE,
    defaultRole,
  }
}

export let getDefaultRole =
  (login: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const rolesData = await UsersAPI.getDefaultRole(login)
    dispatch(setDefaultRole(rolesData))
  }

export const addDefaultRole =
  (defaultRole: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await UsersAPI.addDefaultRole(defaultRole)
      if (response === 'ok') {
        const defaultData = await UsersAPI.getDefaultRole(defaultRole[0])
        dispatch(setDefaultRole(defaultData))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${response}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка добавления роли по умолчанию'))
    }
  }

export const editDefaultRole =
  (defaultRole: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await UsersAPI.editDefaultRole(defaultRole)
      if (response === 'ok') {
        const defaultData = await UsersAPI.getDefaultRole(defaultRole[0])
        dispatch(setDefaultRole(defaultData))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${response}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка редактирования роли по умолчанию'))
    }
  }

export const removeDefaultRole =
  (login: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await UsersAPI.deleteDefaultRole(login)
    if (response === 'ok') {
      const defaultData = await UsersAPI.getDefaultRole(login)
      dispatch(setDefaultRole(defaultData))
    }
  }

type setLoad = {
  type: typeof SET_LOAD
  status: boolean
}

export const setLoader = (status: boolean): setLoad => {
  return {
    type: SET_LOAD,
    status,
  }
}

export const preloadAvatar =
  (file: File, roleName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let resp = await UsersAPI.preloadAvatar(file, roleName)
      console.log(resp)
      if (resp == 'File uploaded') {
        dispatch(setLoader(true))
      } else {
        dispatch(setUserError(`Произошла ошибка: ${resp}`))
      }
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка добавления пользователя'))
    }
  }

export const saveAvatar =
  (roleName: string, config: any): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      await UsersAPI.saveAvatar(roleName, config)
      dispatch(setLoader(false))
    } catch (e: any) {
      dispatch(setUserError('Произошла ошибка сохранения аватара'))
    }
  }

export const deleteAvatar =
  (contentType: string, fileName: string, roleName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    await UsersAPI.deleteAvatar(contentType, fileName, roleName)
    dispatch(setLoader(false))
  }

export const changePass =
  (str: string, pass: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await UsersAPI.changePass(str, pass)
    if (response === 'ok') {
      const usersData = await UsersAPI.getUsers()
      dispatch(setUsers(usersData))
    }
  }
