import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'

const User: FC = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.12777 3L15.8722 3C17.6553 3 18.3018 3.18565 18.9537 3.53427C19.6055 3.88288 20.1171 4.39446 20.4657 5.04631C20.8143 5.69816 21 6.34473 21 8.12777L21 15.8722C21 17.6553 20.8143 18.3018 20.4657 18.9537C20.1171 19.6055 19.6055 20.1171 18.9537 20.4657C18.3018 20.8143 17.6553 21 15.8722 21L8.12777 21C6.34473 21 5.69816 20.8143 5.04631 20.4657C4.39446 20.1171 3.88288 19.6055 3.53427 18.9537C3.18565 18.3018 3 17.6553 3 15.8722L3 8.12777C3 6.34473 3.18565 5.69816 3.53427 5.04631C3.88288 4.39446 4.39446 3.88288 5.04631 3.53427C5.69816 3.18565 6.34473 3 8.12777 3ZM14.5 9.5C14.5 8.11875 13.3813 7 12 7C10.6188 7 9.5 8.11875 9.5 9.5C9.5 10.8812 10.6188 12 12 12C13.3813 12 14.5 10.8812 14.5 9.5ZM7 15.7692V16.3719C7 16.7188 7.28122 17 7.62813 17H16.3719C16.7188 17 17 16.7188 17 16.3719V15.7692C17 13.6154 13.6687 13 12 13C10.3313 13 7 13.6154 7 15.7692Z"
          fill="#118EFF"
        />
      </svg>
    </SvgIcon>
  )
}

export default User
