import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

const style = {
  title: {
    fontSize: '13px',
  },
  descr: {
    fontSize: '20px',
    fontWeight: '700',
  },
}

type ProfileInformationTypes = {
  title: string
  descr: string | undefined
}

export const ProfileInformation: React.FC<ProfileInformationTypes> = (props) => {
  return (
    <Box sx={{ marginTop: '12px', paddingRight: '64px' }}>
      <Typography sx={style.title} gutterBottom component="div">
        {props.title}
      </Typography>
      <Typography sx={style.descr} gutterBottom component="div">
        {props.descr}
      </Typography>
      <Divider variant="fullWidth" />
    </Box>
  )
}
