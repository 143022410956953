export const styles = {
  box: { display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' },
  paper: { width: '880px', height: '442px', borderRadius: '24px' },
  boxLogin: { margin: '64px', height: '314px' },
  column: { width: '360px' },
  title: { fontSize: '50px', fontWeight: 'bold', lineHeight: '110%', mb: '16px' },
  description: { fontSize: '17px', mb: '10px' },
  lang: { mb: '54px' },
  langSelect: {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  enter: { fontSize: '20px', fontWeight: 'bold', mb: '32px' },
  restore: { fontSize: '20px', fontWeight: 'bold', mb: '8px' },
  restoreDesc: { fontSize: '17px', color: '#6E7191' },
  login: { maxWidth: '345px', mb: '16px !important' },
  password: {
    maxWidth: '345px',
    mb: '48px !important',
    '& .MuiFilledInput-root': {
      backgroundColor: '#EFF0F6',
      borderRadius: 4,
      '&:hover': {
        backgroundColor: '#E8E8E8',
      },
      '&.Mui-focused': {
        backgroundColor: '#F0F0F2',
      },
    },
  },
  passwordIcon: { marginRight: '8px' },
  mb48: { mb: '48px !important' },
  mb32: { mb: '32px' },
  button: { mr: 2, borderRadius: '14px' },
}
