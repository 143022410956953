import { instance } from './instance'
import { ProfileType } from '../types/types'

export const ProfileAPI = {
  getProfile() {
    return instance.get<ProfileType>(`/api/profile-api-pickler`).then((res) => {
      !res.data.organizationOpt && Object.assign(res.data, (res.data.organizationOpt = ''))
      !res.data.positionOpt && Object.assign(res.data, (res.data.positionOpt = ['']))
      return res.data
    })
  },
  updateProfile(
    login: string,
    alias: string,
    fio: string,
    mail: string,
    lang: string,
    organizationOpt: string,
    positionOpt: string[],
  ) {
    return instance
      .post<string>(`/api/update-profile`, { login, alias, fio, mail, lang, organizationOpt, positionOpt })
      .then((res) => res.data)
  },
}
