import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { accomulatePermissionsType, FAQType, GroupType, UsersType } from '../../types/types'
import { GroupAPI } from '../../api/group'
import { faqAPI } from '../../api/faq'
import { v4 as uuidv4 } from 'uuid'
const SET_INSTUCTION_LIST = 'SET_INSTUCTION_LIST'
const SET_FAQ_LIST = 'SET_FAQ_LIST'

let initialstate = {
  instruction: [] as Array<string>,
  faq: [] as Array<FAQType>,
}

export type InitialstateType = typeof initialstate

const faq_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_INSTUCTION_LIST: {
      return { ...state, instruction: action.instuction }
    }
    case SET_FAQ_LIST: {
      return { ...state, faq: action.faq }
    }
    default:
      return state
  }
}

export default faq_reducer

type ActionsType = SetInstructionListType | SetFAQListType
// type ActionsType = SetInstructionListType | setAllUsers | setUsersSelectGroup

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetInstructionListType = {
  type: typeof SET_INSTUCTION_LIST
  instuction: Array<string>
}

export let setInstructionList = (instuction: Array<string>): SetInstructionListType => {
  return {
    type: SET_INSTUCTION_LIST,
    instuction,
  }
}

export let getInstructionList = (): ThunkType => async (dispatch: DispatchType) => {
  let instructionData = await faqAPI.getInstructionList()
  dispatch(setInstructionList(instructionData))
}

type SetFAQListType = {
  type: typeof SET_FAQ_LIST
  faq: Array<FAQType>
}

export let setFAQList = (faq: Array<FAQType>): SetFAQListType => {
  return {
    type: SET_FAQ_LIST,
    faq,
  }
}

export let getFAQList = (): ThunkType => async (dispatch: DispatchType) => {
  let FAQData = await faqAPI.getFAQList()
  dispatch(setFAQList(FAQData))
}

export let sendQuestion =
  (theme: string, self: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await faqAPI.sendQuestion(theme, self)
  }

export let insertFAQ = (): ThunkType => async (dispatch: DispatchType) => {
  let id = uuidv4()
  let resp = await faqAPI.insertFAQ(
    id,
    'Соединение потеряно. Попробуйте перезагрузить странницу, чтобы восстановить соединение.',
    'Данная проблема связана с блокировками или плохим интернет соединением на вашем ПК, попробуйте использовать другой ПК или смартфон с хорошим интернетом',
    'ru',
  )
}
