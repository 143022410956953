import { instance } from './instance'
import { DrillsType } from '../types/types'
import { ParticipantsType } from '../types/types'

export const DrillsAPI = {
  getDrills() {
    return instance.get<Array<DrillsType>>(`api/drills`).then((res) => {
      res.data.forEach((i) => {
        !i.withAgreement && Object.assign(i, (i.withAgreement = false))
      })
      res.data.sort((a, b) => b.start - a.start)
      return res.data
    })
  },
  getDrill(drillId: string) {
    return instance.get<DrillsType>(`api/drill-api/` + drillId).then((res) => res.data)
  },
  addDrills(id: string, name: string, start: number, end: number[], withAgreement: boolean) {
    return instance.post<string>(`/api/drill-api`, { id, name, start, end, withAgreement }).then((res) => res.data)
  },
  addDrillsGroup(id: string, name: string, start: number, end: number[], withAgreement: boolean, groupId: string) {
    return instance
      .post<string>(`/api/drill-api/` + groupId, { id, name, start, end, withAgreement })
      .then((res) => res.data)
  },
  addDrillsTeam(id: string, name: string, start: number, end: number[], withAgreement: boolean, teamId: string) {
    return instance
      .post<string>(`/api/drill-api-fromdrill/` + teamId, { id, name, start, end, withAgreement })
      .then((res) => res.data)
  },
  editDrills(id: string, name: string, start: number, end: number[], withAgreement: boolean) {
    return instance.put<string>(`/api/drill-api`, { id, name, start, end, withAgreement }).then((res) => res.data)
  },
  deleteDrills(drillId: string) {
    return instance.delete<string>('/api/drill-api/' + drillId).then((res) => res.data)
  },
  getParticipants(drillId: string) {
    return instance.get<Array<any>>(`/api/team-api/` + drillId).then((res) => res.data)
  },
  setParticipants(userRoles: Array<any>) {
    userRoles.forEach((user) => {
      user.otherRole ? (user.otherRole = [{ $type: `ru.digitalatom.sonet.shared.LEADER` }]) : (user.otherRole = [])
    })
    return instance.put<Array<ParticipantsType>>(`/api/team-list-api`, userRoles).then((res) => res.data)
  },
}
