import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'

const Settings: FC = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.25 2.83333C13.4752 3.58417 13.6329 4.33501 14.8809 5.05537C16.2786 5.43443 16.9621 5.05818 17.5979 4.6343C18.2608 4.19237 18.4576 4.32011 18.7224 4.58057L19.3657 5.22356C19.6603 5.51819 19.7782 5.63605 19.3657 6.40207C18.9939 7.09245 18.5743 7.73497 18.948 9.12764C19.6684 10.383 20.4175 10.6002 21.1667 10.75C21.9479 10.9063 21.9967 11.1357 21.9998 11.5071L22 12.4167C22 12.8333 22 13 21.1667 13.25C20.4158 13.4752 19.665 13.6329 18.9446 14.8809C18.5656 16.2786 18.9418 16.9621 19.3657 17.5979C19.8076 18.2608 19.6799 18.4576 19.4194 18.7224L18.7764 19.3657C18.4818 19.6603 18.364 19.7782 17.5979 19.3657C16.9076 18.9939 16.265 18.5743 14.8724 18.948C13.617 19.6684 13.3998 20.4175 13.25 21.1667C13.0937 21.9479 12.8643 21.9967 12.4929 21.9998L11.7388 22C11.6939 22 11.6429 22 11.5833 22C11.1667 22 11 22 10.75 21.1667C10.5248 20.4158 10.3671 19.665 9.11907 18.9446C7.72138 18.5656 7.03791 18.9418 6.40207 19.3657C5.73915 19.8076 5.54236 19.6799 5.27758 19.4194L4.6343 18.7764C4.33968 18.4818 4.22182 18.364 4.6343 17.5979C5.00606 16.9076 5.42566 16.265 5.05199 14.8724C4.33159 13.617 3.58246 13.3998 2.83333 13.25C2.05208 13.0937 2.00326 12.8643 2.0002 12.4929L2 11.5833C2 11.1667 2 11 2.83333 10.75C3.58417 10.5248 4.33501 10.3671 5.05537 9.11907C5.43443 7.72138 5.05818 7.03791 4.6343 6.40207C4.19237 5.73915 4.32011 5.54236 4.58057 5.27758L5.22356 4.6343C5.51819 4.33968 5.63605 4.22182 6.40207 4.6343C7.09245 5.00606 7.73497 5.42566 9.12764 5.05199C10.383 4.33159 10.6002 3.58246 10.75 2.83333C10.9063 2.05208 11.1357 2.00326 11.5071 2.0002L12.4167 2C12.8333 2 13 2 13.25 2.83333ZM12 7.25C9.37665 7.25 7.25 9.37665 7.25 12C7.25 14.6234 9.37665 16.75 12 16.75C14.6234 16.75 16.75 14.6234 16.75 12C16.75 9.37665 14.6234 7.25 12 7.25Z"
          fill="#118EFF"
        />
      </svg>
    </SvgIcon>
  )
}

export default Settings
