import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { NONAME } from 'dns'
import { grey } from '@mui/material/colors'
import { ReactComponent as Arrow_drop_down } from '../../../assets/icon/profile/arrow_drop_down.svg'
import InputAdornment from '@mui/material/InputAdornment'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { RefObject } from '../../../types/types'

type RedditTextFieldPropsTypes = {
  gta: string | undefined
}

const RedditTextField = styled((props: TextFieldProps | PropTypes) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
        // endAdornment: (
        //   <InputAdornment sx={{ mr: -0.6 }} position="end">
        //     <ArrowDropDownIcon />
        //   </InputAdornment>
        // ),
      } as Partial<OutlinedInputProps>
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '0px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 16,
    backgroundColor: theme.palette.mode === 'light' ? '#EFF0F6' : '#EFF0F6',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      borderColor: '#EFF0F6',
      border: '0px solid #e2e2e1',
    },
    '&.Mui-active': {
      borderColor: '#EFF0F6',
      border: '0px solid #e2e2e1',
    },
    '& label.Mui-focused': {
      color: '#EFF0F6',
    },
  },
}))

type PropTypes = {
  label?: string
  type?: string
  readonly?: boolean
  value?: string
  icon?: string
  rows?: number
  inputRef?: RefObject<HTMLInputElement>
  changeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export default function CustomizedInputs(props: PropTypes) {
  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: 'grid',
        gridTemplateColumns: { sm: '1fr' },
        gap: 2,
        // maxWidth: '640px',
      }}
    >
      {props.rows ? (
        <RedditTextField
          type={props.type ? props.type : 'text'}
          inputRef={props.inputRef}
          label={props.label}
          defaultValue={props.value}
          id="reddit-input"
          variant="filled"
          rows={props.rows}
          multiline
          // value={props.value}
          // inputProps={{
          //   readOnly: props.readonly,
          // }}
          InputLabelProps={{
            style: { color: '#6E7191' },
          }}
        />
      ) : (
        <RedditTextField
          type={props.type ? props.type : 'text'}
          inputRef={props.inputRef}
          label={props.label}
          defaultValue={props.value}
          id="reddit-input"
          variant="filled"
          onChange={props.changeInput}
          // value={props.value}
          inputProps={{
            readOnly: props.readonly,
          }}
          InputLabelProps={{
            style: { color: '#6E7191' },
          }}
        />
      )}
    </Box>
  )
}
