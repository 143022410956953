export const styles = {
  box: {
    width: '100%',
    height: '80px',
    position: 'absolute',
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '60%',
    cursor: 'pointer',
    mt: 1,
    objectFit: 'contain',
    ml: 4,
  },
  nameDrill: {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: 2,
  },
}
