export const ALL = '*'
export const MAIN = '/'
export const DRILL = '/drill/:drillId'
export const GENERAL = '/drill/:drillId/general'
export const JOURNAL = '/drill/:drillId/journal'
export const USERS = '/users'
export const RIGHTS = '/rights'
export const ROLES = '/roles'
export const GROUPS = '/groups'
export const FAQ = '/faq'
export const FILE = '/get-file/'
export const SETTINGS = '/settings'
