import { instance } from './instance'
import { GroupType, UsersType } from '../types/types'

type socialRoleTypeType = {
  $type: string
}

export const GroupAPI = {
  getGroups() {
    return instance.get<Array<GroupType>>(`/api/groups-list`).then((res) => res.data)
  },
  getAllUsers() {
    return instance.get<Array<UsersType>>(`/api/user-api`).then((res) => res.data)
  },
  addGroup(id: string, name: string, description: string[], listLogins: string[]) {
    return instance
      .post<string>(`/api/groups-api`, { group: { id, name, description }, listLogins })
      .then((res) => res.data)
  },
  getInfoAboutSelectGroup(groupID: string) {
    return instance.get<UsersType[]>(`/api/groups-users/` + groupID).then((res) => res.data)
  },
  editGroup(id: string, name: string, description: string[], listLogins: string[]) {
    return instance
      .put<string>(`/api/groups-api`, { group: { id, name, description }, listLogins })
      .then((res) => res.data)
  },
  deleteGroups(groups: string[]) {
    return instance.delete<string>('/api/groups-api', { data: groups }).then((res) => res.data)
  },
  sendMail(id: string, subject: string, content: string) {
    return instance.post<string>('/api/groups-mail', { id, subject, content }).then((res) => res.data)
  },
}
