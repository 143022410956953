import axios from 'axios'

const URL = process.env.NODE_ENV == 'development' ? 'https://sonet.digitalatom.ru/' : '/'

export const wssURL =
  process.env.NODE_ENV == 'development' ? 'wss://sonet.digitalatom.ru' : window.location.origin.replace('https', 'wss')

export const instance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  responseType: 'json',
  baseURL: URL,
})
