import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { DrillsType, UsersType } from '../../types/types'
import { DrillsAPI } from '../../api/drills'
import { GroupAPI } from '../../api/group'
import { setUsersSelectGroup } from './groups_reducer'
const SET_DRILLS = 'SET_DRILLS'
const SET_PARTICIPANTS_TEAM = 'SET_PARTICIPANTS_TEAM'
const SET_PARTICIPANTS_TEAM_NULL = 'SET_PARTICIPANTS_TEAM_NULL'

const initialState = {
  drills: [] as Array<DrillsType>,
  participantsSelectTeam: [] as Array<any>,
}

const drills_reducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SET_DRILLS: {
      return { ...state, drills: action.drills }
    }
    case SET_PARTICIPANTS_TEAM: {
      return { ...state, participantsSelectTeam: action.participants }
    }
    case SET_PARTICIPANTS_TEAM_NULL: {
      return { ...state, participantsSelectTeam: [] }
    }
    default:
      return state
  }
}

export default drills_reducer

export type SetDrillsType = {
  type: typeof SET_DRILLS
  drills: Array<DrillsType>
}

type ActionsType = SetDrillsType | setParticipantsSelectTeamType | setParticipantsSelectTeamNullType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

export const setDrills = (drills: Array<DrillsType>): SetDrillsType => {
  return {
    type: SET_DRILLS,
    drills,
  }
}

export const getDrills = (): ThunkType => async (dispatch: DispatchType) => {
  const drillsData = await DrillsAPI.getDrills()
  dispatch(setDrills(drillsData))
}

export const getDrill =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const drillData: any = await DrillsAPI.getDrill(drillId)
    dispatch(setDrills(drillData))
  }

export const addDrill =
  (id: string, name: string, start: number, end: number[], withAgreement: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.addDrills(id, name, start, end, withAgreement)
    if (response == 'ok') {
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    }
  }

export const addDrillGroup =
  (id: string, name: string, start: number, end: number[], withAgreement: boolean, groupId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.addDrillsGroup(id, name, start, end, withAgreement, groupId)
    if (response == 'ok') {
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    }
  }

export const addDrillTeam =
  (id: string, name: string, start: number, end: number[], withAgreement: boolean, teamId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.addDrillsTeam(id, name, start, end, withAgreement, teamId)
    if (response == 'ok') {
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    }
  }

export const editDrill =
  (id: string, name: string, start: number, end: number[], withAgreement: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.editDrills(id, name, start, end, withAgreement)
    if (response == 'ok') {
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    }
  }

export const removeDrill =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.deleteDrills(drillId)
    if (response == 'ok') {
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    }
  }

type setParticipantsSelectTeamType = {
  type: typeof SET_PARTICIPANTS_TEAM
  participants: Array<any>
}

type setParticipantsSelectTeamNullType = {
  type: typeof SET_PARTICIPANTS_TEAM_NULL
  participants: []
}

export const setParticipantsSelectTeam = (participants: Array<any>): setParticipantsSelectTeamType => {
  return {
    type: SET_PARTICIPANTS_TEAM,
    participants,
  }
}

export const nullParticipantsSelectTeam = (participants: any): setParticipantsSelectTeamNullType => {
  return {
    type: SET_PARTICIPANTS_TEAM_NULL,
    participants,
  }
}

export const getParticipants =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let participantsTeam = await DrillsAPI.getParticipants(drillId)
    dispatch(setParticipantsSelectTeam(participantsTeam))
  }

export const nullParticipants =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let participants: any = []
    dispatch(nullParticipantsSelectTeam(participants))
  }

export const setParticipants =
  (userRoles: Array<any>): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.setParticipants(userRoles)
  }
