import { createTheme, ThemeOptions } from '@mui/material/styles'
import { BackgroundSaes } from '../assets/img'
import Inter from './typography'
import MainPalette from './palette'

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  palette: MainPalette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        Inter,
        html: {
          height: '100%',
        },
        body: {
          backgroundImage: `linear-gradient(0deg, rgba(206, 234, 255, 0.1), rgba(206, 234, 255, 0.1)), url(${BackgroundSaes}), linear-gradient(0deg, #CEEAFF, #CEEAFF)`,
          backgroundBlendMode: 'normal, luminosity, normal',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'repeat-y',
          scrollbarWidth: 'thin',
          scrollbarColor: '#A4B3C670 #FFFFFF01',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#FFFFFF00',
            borderRadius: '8px',
            width: '8px',
            height: '8px',
            position: 'absolute',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#A4B3C680',
            minHeight: 24,
            border: 'none',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            margin: '1px 0px 1px 5px',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#9eacbd',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#9eacbd',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#9eacbd',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            display: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
        },
      },
    },
  },
})
