import React, { forwardRef, ForwardedRef } from 'react'
import { TextFieldProps, styled, TextField, OutlinedInputProps, alpha } from '@mui/material'

//поле ввода
const TextFieldModal = styled(
  forwardRef((props: TextFieldProps, ref: ForwardedRef<any>) => (
    <TextField
      inputRef={ref}
      variant="filled"
      fullWidth
      style={{ marginBottom: '1%' }}
      InputLabelProps={{
        style: { color: '#6E7191' },
      }}
      InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
    />
  )),
)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#EFF0F6',
    borderRadius: 16,
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
    '&.Mui-focused': {
      backgroundColor: '#F0F0F2',
    },
  },
}))

export default TextFieldModal
