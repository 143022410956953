import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'

const questionMark: FC = () => {
  return (
    <SvgIcon>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM8.75 16.25V15.75C8.75 15.1977 9.19772 14.75 9.75 14.75H10.25C10.8023 14.75 11.25 15.1977 11.25 15.75V16.25C11.25 16.8023 10.8023 17.25 10.25 17.25H9.75C9.19772 17.25 8.75 16.8023 8.75 16.25ZM12.17 10.17C11.6347 10.7127 11.2487 11.1835 11.0858 12.0057C11.0783 12.0436 11.0712 12.0872 11.0644 12.1365C10.9962 12.6314 10.5733 13 10.0737 13H10C9.47113 13 9.0424 12.5713 9.0424 12.0424C9.0424 12.0153 9.04355 11.9882 9.04588 11.9612C9.05419 11.8635 9.06321 11.7897 9.07293 11.7396C9.22762 10.9427 9.62005 10.2276 10.17 9.67L11.41 8.41C11.78 8.05 12 7.55 12 7C12 5.9 11.1 5 10 5C9.30103 5 8.68282 5.36339 8.32495 5.91057C8.26164 6.00737 8.20444 6.13709 8.15335 6.29971C8.02243 6.71646 7.63615 7 7.19932 7H7C6.52841 7 6.14612 6.6177 6.14612 6.14612C6.14612 6.08264 6.1532 6.01937 6.16724 5.95746C6.22771 5.6905 6.29635 5.47471 6.37314 5.31009C7.00979 3.94528 8.394 3 10 3C12.21 3 14 4.79 14 7C14 7.88 13.64 8.68 13.07 9.25L12.17 10.17Z"
          fill="#A4B3C6"
        />
      </svg>
    </SvgIcon>
  )
}

export default questionMark
