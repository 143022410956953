import React, { FC, useState, useRef, useEffect, useContext } from 'react'
import {
  Paper,
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  SelectChangeEvent,
  Snackbar,
  Alert,
} from '@mui/material'
import { RosLogo, DigitalAtom, RosenergoAtom } from '../../assets/img'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { styles } from './login-style'
import { login, actions } from '../../redux/reducers/auth_reducer'
import { useDispatch, useSelector } from 'react-redux'
import { Restore } from './restore'
import TextFieldModal from '../../components/modal/textFieldModal'
import { AppStateType } from '../../redux/store'
import { LanguageContext, translate } from '../../constants/lang'

export const Login: FC = () => {
  let dispatch = useDispatch()
  const valueLoginRef = useRef<HTMLInputElement>()
  const valuePasswordRef = useRef<HTMLInputElement>()
  const [loginState, setLoginState] = useState('auth')
  const [openError, setOpenError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [lang, setLang] = React.useState<'RU' | 'EN'>('RU')
  const { language, changeLanguage } = useContext(LanguageContext)

  let isError = useSelector((store: AppStateType) => store.Auth.messages)

  useEffect(() => {
    if (isError !== '') {
      setOpenError(true)
      const clearError = setTimeout(() => {
        dispatch(actions.setResponseLoginErrorMessage(''))
      }, 4000)
      return () => clearTimeout(clearError)
    }
  }, [isError])

  const closeError = () => {
    setOpenError(false)
    dispatch(actions.setResponseLoginErrorMessage(''))
  }

  const sendValue = (): void => {
    dispatch(login(valueLoginRef.current!.value, valuePasswordRef.current!.value, lang))
  }

  const changeLanguages = (event: SelectChangeEvent) => {
    if (event.target.value === 'ru') {
      changeLanguage('ru')
    } else if (event.target.value === 'en') {
      changeLanguage('en')
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={openError}
        onClose={closeError}
      >
        <Alert onClose={closeError} severity="error">
          Произошла ошибка: "{isError}"
        </Alert>
      </Snackbar>
      <Box sx={styles.box}>
        <Paper sx={styles.paper}>
          <Box sx={styles.boxLogin}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={styles.column}>
                <Typography sx={styles.title}>
                  <img src={RosLogo} alt="roslogo" />
                </Typography>
                <Typography sx={styles.description}>{translate('descriptionProject', language)}</Typography>
                <FormControl size="small" sx={styles.lang}>
                  <Select
                    labelId="demo-simple-select-lang"
                    id="demo-simple-lang"
                    value={language}
                    onChange={changeLanguages}
                    sx={styles.langSelect}
                  >
                    <MenuItem value={'ru'}>🇷🇺</MenuItem>
                    <MenuItem value={'en'}>🇬🇧󠁢󠁿</MenuItem>
                  </Select>
                </FormControl>
                <Grid container>
                  <Grid item xs={6}>
                    <img src={DigitalAtom} alt="digitalatom" />
                  </Grid>
                  <Grid item xs={6}>
                    <img src={RosenergoAtom} alt="digitalatom" />
                  </Grid>
                </Grid>
              </Grid>
              {loginState === 'auth' ? (
                <Grid item xs={6} sx={styles.column}>
                  <Typography sx={styles.enter}>{translate('login', language)}</Typography>
                  <TextFieldModal
                    label={translate('labelLogin', language)}
                    type="text"
                    inputRef={valueLoginRef}
                    sx={styles.login}
                  />
                  <TextField
                    label={translate('labelPassword', language)}
                    type={showPassword ? 'text' : 'password'}
                    inputRef={valuePasswordRef}
                    variant="filled"
                    fullWidth
                    InputLabelProps={{
                      style: { color: '#6E7191' },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={styles.passwordIcon}>
                          <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    sx={styles.password}
                  />
                  <Grid container>
                    <Grid item xs={4}>
                      <Button variant="contained" size="large" onClick={sendValue} sx={styles.button}>
                        {translate('login', language)}
                      </Button>
                    </Grid>
                    <Grid item xs={8}>
                      <Button size="large" onClick={() => setLoginState('restore')} sx={styles.button}>
                        {translate('restoreAccess', language)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Restore setLoginState={setLoginState} />
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  )
}
