import React, { FC, useState } from 'react'
import { Box, Typography, Icon, Grid, Avatar, Stack } from '@mui/material'
import { RosLogo } from '../../assets/img'
import { styles } from './header-style'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled, alpha } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppStateType } from '../../redux/store'
import { ChatSettings } from './modals/ChatSettings'
import { setChatSettings as updateChatSettings } from '../../redux/reducers/chat_reducer'
import { EditParticipants } from '../drills/drill-list/modals/EditParticipants'
import { LogoutModal } from './modals/LogoutModal'
import { AppBarComponent } from './AppBarComponent'

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    marginTop: theme.spacing(1),
    minWidth: 150,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) },
    },
  },
}))

export const Header: FC = () => {
  const location = useLocation().pathname
  const drillId = location.includes('drill') && location.slice(7, 43)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()
  // const handleOnClick = useCallback(() => navigate('/chat', { replace: true }), [navigate])
  const [isOpenUserProfile, setOpen] = React.useState(false)
  const openUserProfile = () => setOpen(true)
  const closeUserProfile = () => setOpen(false)

  const [isOpenEditParticipants, setOpenEditParticipants] = useState(false)
  const openEditParticipants = () => {
    setOpenEditParticipants(true)
    setAnchorEl(null)
  }
  const closeEditParticipants = () => setOpenEditParticipants(false)

  const [isOpenChatSettings, setOpenChatSettings] = React.useState(false)
  const openChatSettings = () => setOpenChatSettings(true)
  const closeChatSettings = () => setOpenChatSettings(false)

  //для модального окна разлогинится
  const [isOpenLogout, setOpenLogout] = React.useState(false)
  const openLogout = () => setOpenLogout(true)
  const closeLogout = () => setOpenLogout(false)

  const selectMenuItem = (LINK: string) => {
    closeMenu()
    navigate(LINK, { replace: true })
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <ChatSettings
          openChatSettings={openChatSettings}
          closeChatSettings={closeChatSettings}
          isOpenChatSettings={isOpenChatSettings}
        />
        <EditParticipants
          openEditParticipants={openEditParticipants}
          closeEditParticipants={closeEditParticipants}
          isOpenEditParticipants={isOpenEditParticipants}
          drillId={drillId}
        />
        <LogoutModal closeLogout={closeLogout} isOpenLogout={isOpenLogout} />
        <AppBarComponent openEditParticipants={openEditParticipants} openChatSettings={openChatSettings} />
      </Box>
    </>
    // <>
    //   <Box sx={styles.box}>
    //     <img src={RosLogo} alt="roslogo" width="163" height="42" />
    //     <Typography>Заголовок</Typography>
    //   </Box>
    // </>
  )
}
