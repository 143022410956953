import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'

const Note: FC = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 3L18 3C19.6569 3 21 4.34315 21 6L21 18C21 19.6569 19.6569 21 18 21L6 21C4.34315 21 3 19.6569 3 18L3 6C3 4.34315 4.34315 3 6 3ZM8 13L16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11L8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13ZM8 9L16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7L8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9ZM8 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15L8 15C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17Z"
          fill="#118EFF"
        />
      </svg>
    </SvgIcon>
  )
}

export default Note
